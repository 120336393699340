import { SecondaryCircuits } from 'Quotes/types/store';
import { ProductType } from 'Quotes/types/productTypes';

export enum Supplier {
  BT_WHOLESALE = 'BT_Wholesale',
  COLT = 'Colt',
  OPENREACH = 'BT_OpenReach',
  SKY = 'Sky',
  SKY_ITS = 'Sky_ITS',
  NONE = 'None',
  TALKTALK = 'TalkTalk',
  VIRGIN = 'VM_National',
  CITYFIBRE = 'CityFibre',
  VODAFONE = 'Vodafone_National',
  ONAT = 'ONAT', // Keeping this for legacy reasons
  NNAT = 'NNAT', // NNAT supersedes ONAT (it's the same thing though)
  VORBOSS = 'Vorboss',
}

export const NNATSuppliers: Array<string | null> = [Supplier.NNAT, Supplier.ONAT];

export enum SupplierEndType {
  AEnd,
  BEnd,
}

export const supportsFTTPAggregation = (supplier: Supplier | string | null) => {
  return supplier !== Supplier.SKY && supplier !== Supplier.CITYFIBRE;
};

export const supportsFTTPAggregationSecondaryCircuits = (
  productType: ProductType | null | undefined,
  secondaryCircuits: SecondaryCircuits | undefined
) => {
  // Disable for RO2 on P2NNI until Cerillion problem is resolved
  return productType !== ProductType.P2NNI || !secondaryCircuits || !secondaryCircuits.enabled;
};

export const isSupplier = (supplier: string | null | undefined): supplier is Supplier => {
  if (!supplier) return false;
  return Object.values(Supplier).includes(supplier as Supplier);
};
