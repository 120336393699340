import classNames from 'classnames';
import React, { FC } from 'react';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import styled from 'styled-components';
import { toLower } from 'lodash';

interface IPriceProps {
  selected: boolean;
  price: number;
  className?: string;
}

interface IPriceOnApplicationProps {
  selected: boolean;
  className?: string;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  padding: 10px 20px;
  background-color: #f1f5f7;

  &.selected {
    background-color: ${(props) => props.theme.colours.primaryB1};
    color: white;
  }

  &.PoA {
    font-size: 22px;
    padding: 15px 20px;
  }
`;

export const PriceLabel = ({
  amortisedAnnualPrice,
  poA,
  selected,
  identifierPrefix,
  dataTestId,
}: {
  amortisedAnnualPrice: number | null;
  selected: boolean | undefined;
  identifierPrefix: string;
  poA: boolean;
  dataTestId: string;
}) => {
  if (amortisedAnnualPrice !== null) {
    return (
      <Price
        selected={selected!}
        price={amortisedAnnualPrice!}
        className={`${toLower(identifierPrefix)}-price`}
        data-testid={dataTestId}
      />
    );
  }

  if (poA) {
    return <PriceOnApplication selected={selected!} className={`${toLower(identifierPrefix)}-price`} />;
  }

  return <NoPriceFound selected={selected!} className={`${toLower(identifierPrefix)}-price`} />;
};

const Price: FC<React.PropsWithChildren<IPriceProps & { 'data-testid'?: string }>> = ({
  selected,
  price,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <Container className={classNames({ selected }, className)} data-testid={dataTestId}>
      {currencyFormatter.format(price)}
    </Container>
  );
};

const PriceOnApplication: FC<React.PropsWithChildren<IPriceOnApplicationProps & { 'data-testid'?: string }>> = ({
  selected,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <Container className={classNames({ selected }, className, 'PoA')} data-testid={dataTestId}>
      Price on application
    </Container>
  );
};

const NoPriceFound: FC<React.PropsWithChildren<IPriceOnApplicationProps & { 'data-testid'?: string }>> = ({
  selected,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <Container className={classNames({ selected }, className, 'PoA')} data-testid={dataTestId}>
      No price found
    </Container>
  );
};
