import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Supplier } from 'Quotes/types/supplier';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';
import Icon from 'shared/components/atoms/Icon';
import PresentForRoles from 'Auth/PresentForRoles';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { userPermissions } from 'shared/utils/permissions';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { PriceCard } from '../PriceCard';
import { BandwidthLinkComponent, SupplierImageComponent } from '../OpticalP2P/Components';

const identifierPrefix = 'DIA';

interface DIAProps {
  selected?: boolean;
  annualIPCharge: number | null;
  onClick?: () => void;
  aEndAccessMethod: IQuote['aEndAccessMethod'];
  aEndBandwidth: IQuote['aEndBandwidth'];
  aEndPostcode: string;
  amortisedAnnualPrice: number | null;
  aEndSupplier: Supplier;
  aEndPrice: number | null;
  priceId: string | null;
  className?: string;
  readonly: boolean;
  product_sub_type: IPriceData['product_sub_type'];
  bandwidth?: string;
  term_length_in_months?: IPriceData['term_length_in_months'];
  isPoA?: boolean;
  supportsSecondaryCircuits: boolean;
}

export const DIA: FunctionComponent<React.PropsWithChildren<DIAProps>> = ({
  selected = false,
  onClick,
  amortisedAnnualPrice,
  aEndAccessMethod,
  aEndBandwidth,
  aEndPostcode,
  aEndSupplier,
  aEndPrice,
  priceId,
  annualIPCharge,
  readonly,
  product_sub_type,
  bandwidth,
  term_length_in_months,
  isPoA = false,
  supportsSecondaryCircuits,
}) => {
  return (
    <PriceCard
      identifierPrefix={identifierPrefix}
      priceId={priceId!}
      readonly={readonly}
      selected={selected}
      onClick={onClick}
      aEndPostcode={aEndPostcode}
      amortisedAnnualPrice={amortisedAnnualPrice}
      product_sub_type={product_sub_type}
      term_length_in_months={term_length_in_months}
      bandwidth={bandwidth}
      isPoA={isPoA}
      supportsSecondaryCircuits={supportsSecondaryCircuits}
    >
      <ul className="list-unstyled">
        <li>
          <Icon className="secondary" name="cloud_queue" size="xxxLarge" />
        </li>
        <li>Dedicated Internet Access (DIA)</li>
        <BandwidthLinkComponent />
        <SupplierImageComponent supplier={aEndSupplier} />
        <li data-testid="a-end-supplier">{presentSupplierNameForPriceTile(aEndSupplier)}</li>
        {aEndBandwidth && <li className="text-secondary a-end-bandwidth">Bandwidth: {formatBandwidth(aEndBandwidth)}</li>}
        {aEndAccessMethod && <li className="text-secondary a-end-access-method">{aEndAccessMethod}</li>}
        <PresentForRoles roles={userPermissions.costs}>
          <li className="text-secondary a-end-ip-cost small">
            Cost: {currencyFormatter.format(aEndPrice ? aEndPrice + (annualIPCharge ? annualIPCharge : 0) : 0)}
          </li>
        </PresentForRoles>
      </ul>
    </PriceCard>
  );
};

export const StyledDIA = styled(DIA)``;
