import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import { useSelector } from 'react-redux';
import { selectQuote, selectSelectedPrice } from 'Quotes/selectors';
import { selectUserRoles } from 'User/selectors';
import { calculateAnnualPrice, calculateUpFront } from 'Quotes/QuoteBuilder/utils/price';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { getSelectedSecondaryPriceFromPrice } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { Term } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/Term';
import { InstallAndDiscount } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/InstallAndDiscount';
import { FttpAggPrice } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/FttpAggPrice';
import { AnnualPriceAndDiscount } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/AnnualPriceAndDiscount';
import { CablingRequested } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/CablingRequested';
import {
  MDIA_ENGINEER_CHARGE,
  MDIA_RACK_MOUNT_KIT_CHARGE,
} from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TillReceiptInternal';
import { getPrimaryIPCharge, getSecondaryIPCharge } from 'Quotes/QuoteBuilder/utils/costsUtilities';

type Amount = {
  amount: number;
};

export const Amount: FunctionComponent<React.PropsWithChildren<Amount>> = ({ amount }) => {
  const formatted = currencyFormatter.format(amount);

  return amount > 0 ? (
    <strong data-testid="order-summary-annual-cost">{formatted}</strong>
  ) : (
    <span data-testid="order-summary-install-cost">{formatted}</span>
  );
};

export const TermAndPrice: FunctionComponent = () => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const secondarySelectedPrice = getSelectedSecondaryPriceFromPrice(selectedPrice);
  const quote = useSelector(selectQuote);
  const userRoles = useSelector(selectUserRoles);
  const cablingRequested = selectedPrice.a_end_cabling_type !== null || selectedPrice.b_end_cabling_type !== null;
  const isMDIA = quote.location.aEnd.is_managed_dia;

  const primaryIPCharge = getPrimaryIPCharge(quote);
  const secondaryIPCharge = getSecondaryIPCharge(quote);

  const isAmortised = selectedPrice.amortised ?? true;

  const primaryInstallPrice = calculateUpFront(
    isAmortised,
    selectedPrice.net_amortised_install_price ?? 0,
    selectedPrice.net_install_price ?? 0
  );

  const secondaryInstallPrice = calculateUpFront(
    isAmortised,
    secondarySelectedPrice?.net_amortised_install_price ?? 0,
    secondarySelectedPrice?.net_install_price ?? 0
  );

  const primaryAnnual = calculateAnnualPrice(
    isAmortised,
    selectedPrice.net_amortised_annual_price ?? 0,
    selectedPrice.net_annual_price ?? 0
  );

  const secondaryAnnualWithPrimaryIPCharge = calculateAnnualPrice(
    isAmortised,
    secondarySelectedPrice?.net_amortised_annual_price ?? 0,
    secondarySelectedPrice?.net_annual_price ?? 0
  );

  // For dia/mdia, because the calculateAnnualPrice for secondary returns the annual price + the PRIMARY IP charge.
  // The PRIMARY IP needs removed and SECONDARY IP Charge added
  const secondaryAnnual = secondaryAnnualWithPrimaryIPCharge - primaryIPCharge + secondaryIPCharge;

  const installPrice = primaryInstallPrice + secondaryInstallPrice;
  const annualPrice = primaryAnnual + (secondarySelectedPrice ? secondaryAnnual : 0);

  const annualDiscount = (selectedPrice.annual_discount ?? 0) + (secondarySelectedPrice?.annual_discount ?? 0);
  const installDiscount = (selectedPrice.install_discount ?? 0) + (secondarySelectedPrice?.install_discount ?? 0);
  const fttpAggregationCharge =
    (selectedPrice.fttp_aggregation_charge ?? 0) + (secondarySelectedPrice?.fttp_aggregation_charge ?? 0);

  const options = quote.location.aEnd.secondRouterOptions;
  const engineerInstallationRequired = options?.engineerInstallationRequired;
  const rackMountKitRequired = options?.rackMountKitRequired;
  const mdiaEngineerCharge = engineerInstallationRequired ? MDIA_ENGINEER_CHARGE : 0;
  const mdiaRackMountKitCharge = rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_CHARGE : 0;
  const secondaryMdiaExtras = mdiaEngineerCharge + mdiaRackMountKitCharge;
  const term = Number(selectedPrice.term_length_in_months ?? 0) / 12;
  const secondaryMdiaAmortisedExtras = secondaryMdiaExtras / term;

  return (
    <div className="row no-gutters mt-3">
      <Column defaultWidth={2}>
        <span className="sub-heading">Term &amp; Price</span>
      </Column>
      <Term quote={quote} />
      <InstallAndDiscount
        amount={!isAmortised && isMDIA ? installPrice + secondaryMdiaExtras : installPrice}
        toCheck={userRoles}
        value={installDiscount}
      />
      <FttpAggPrice quote={quote} amount={fttpAggregationCharge} />
      <AnnualPriceAndDiscount
        amount={isAmortised && isMDIA ? annualPrice + secondaryMdiaAmortisedExtras : annualPrice}
        toCheck={userRoles}
        value={annualDiscount}
      />
      <CablingRequested quote={quote} cablingRequested={cablingRequested} selectedPrice={selectedPrice} />
    </div>
  );
};
