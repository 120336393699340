import React, { FunctionComponent } from 'react';
import { Supplier } from 'Quotes/types/supplier';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';
import styled from 'styled-components';
import PresentForRoles from 'Auth/PresentForRoles';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { CloudProviderImage } from '../../../CloudProvider';
import { IPriceData, ProviderName } from 'Quotes/types/store';
import { userPermissions } from 'shared/utils/permissions';
import { PriceCard } from '../PriceCard';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { BandwidthLinkComponent, SupplierImageComponent } from '../OpticalP2P/Components';

const identifierPrefix = 'P2CCT';

interface IP2CCTProps {
  selected?: boolean;
  provider: ProviderName;
  aEndPostcode: string;
  aEndSupplier: Supplier;
  amortisedAnnualPrice: number | null;
  aEndPrice: number | null;
  priceId: string | null;
  className?: string;
  cloudConnectAnnual: number | null;
  readonly: boolean;
  onClick?: () => void;
  product_sub_type: IPriceData['product_sub_type'];
  bandwidth?: string;
  term_length_in_months?: IPriceData['term_length_in_months'];
  isPoA?: boolean;
}

export const P2CCT: FunctionComponent<React.PropsWithChildren<IP2CCTProps>> = ({
  selected = false,
  onClick,
  amortisedAnnualPrice,
  aEndPostcode,
  aEndSupplier,
  provider,
  aEndPrice,
  priceId,
  cloudConnectAnnual,
  readonly,
  product_sub_type,
  bandwidth,
  term_length_in_months,
  isPoA = false,
}) => (
  <PriceCard
    identifierPrefix={identifierPrefix}
    priceId={priceId!}
    readonly={readonly}
    selected={selected}
    onClick={onClick}
    aEndPostcode={aEndPostcode}
    amortisedAnnualPrice={amortisedAnnualPrice}
    product_sub_type={product_sub_type}
    term_length_in_months={term_length_in_months}
    bandwidth={bandwidth}
    isPoA={isPoA}
  >
    <ul className="list-unstyled">
      <li>
        <CloudProviderImage cloudProvider={provider} />
      </li>
      {provider !== ProviderName.NOT_DEFINED && <li className="provider-name">{provider}</li>}
      {bandwidth && <li className="text-secondary a-end-bandwidth">Bandwidth: {formatBandwidth(bandwidth)}</li>}
      <PresentForRoles roles={userPermissions.costs}>
        <li className="text-secondary cloud-connect-annual-cost">
          Cost: {currencyFormatter.format(cloudConnectAnnual ?? 0)}
        </li>
      </PresentForRoles>
      <BandwidthLinkComponent />
      <SupplierImageComponent supplier={aEndSupplier} />
      <li className="mt-2" data-testid="b-end-postcode">
        {aEndPostcode}
      </li>
      <li data-testid="b-end-supplier">{presentSupplierNameForPriceTile(aEndSupplier)}</li>
      {bandwidth && <li className="text-secondary b-end-bandwidth">Bandwidth: {formatBandwidth(bandwidth)}</li>}
      <PresentForRoles roles={userPermissions.costs}>
        <li className="text-secondary a-end-cost small">Cost: {currencyFormatter.format(aEndPrice ?? 0)}</li>
      </PresentForRoles>
    </ul>
  </PriceCard>
);

export const StyledP2CCT = styled(P2CCT)``;
