import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Supplier } from 'Quotes/types/supplier';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';
import PresentForRoles from 'Auth/PresentForRoles';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { userPermissions } from 'shared/utils/permissions';
import { getNNIOrDataCentreLabel } from 'Quotes/QuoteBuilder/utils/getSelectedNNIReference';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { PriceCard } from '../PriceCard';
import classNames from 'classnames';
import { BandwidthLinkComponent, SupplierImageComponent } from '../OpticalP2P/Components';

const identifierPrefix = 'P2NNI';

interface IP2NNIProps {
  selected?: boolean;
  onClick?: () => void;
  nniLabel: string | null | undefined;
  nniLocation: string | null | undefined;
  bEndPostcode: string;
  amortisedAnnualPrice: number | null;
  bEndAccessMethod: IQuote['bEndAccessMethod'];
  bEndBandwidth: IQuote['bEndBandwidth'];
  bEndSupplier: Supplier;
  bEndPrice: number | null;
  priceId: string | null;
  nniReference?: string | null;
  nniPopName?: string | null;
  nniShadowReference?: string;
  className?: string;
  readonly: boolean;
  product_sub_type: IPriceData['product_sub_type'];
  bandwidth?: string;
  term_length_in_months?: IPriceData['term_length_in_months'];
  isPoA?: boolean;
  supportsSecondaryCircuits: boolean;
}

export const P2NNI: FunctionComponent<React.PropsWithChildren<IP2NNIProps>> = ({
  selected = false,
  className,
  onClick,
  amortisedAnnualPrice,
  bEndAccessMethod,
  bEndBandwidth,
  bEndPostcode,
  bEndSupplier,
  bEndPrice,
  priceId,
  nniReference,
  nniShadowReference,
  nniPopName,
  readonly,
  nniLabel,
  nniLocation,
  product_sub_type,
  bandwidth,
  term_length_in_months,
  isPoA = false,
  supportsSecondaryCircuits,
}) => (
  <PriceCard
    identifierPrefix={identifierPrefix}
    priceId={priceId!}
    readonly={readonly}
    selected={selected}
    onClick={onClick}
    bEndPostcode={bEndPostcode}
    amortisedAnnualPrice={amortisedAnnualPrice}
    product_sub_type={product_sub_type}
    term_length_in_months={term_length_in_months}
    bandwidth={bandwidth}
    isPoA={isPoA}
    supportsSecondaryCircuits={supportsSecondaryCircuits}
  >
    <ul className={classNames(className, 'list-unstyled')}>
      <li className="nni-header">NNI{nniShadowReference && ' & Shadow VLAN'}</li>
      <li className="nni-text">
        {nniLabel || getNNIOrDataCentreLabel(nniPopName, nniReference)} {nniLocation}
      </li>
      {nniShadowReference && (
        <li className="nni-text">
          <strong>Shadow VLAN:</strong> {nniShadowReference}
        </li>
      )}
      <BandwidthLinkComponent />
      <SupplierImageComponent supplier={bEndSupplier} />
      <li data-testid="b-end-supplier">{presentSupplierNameForPriceTile(bEndSupplier)}</li>
      {bEndBandwidth && <li className="text-secondary b-end-bandwidth">Bandwidth: {formatBandwidth(bEndBandwidth)}</li>}
      {bEndAccessMethod && <li className="text-secondary b-end-access-method">{bEndAccessMethod}</li>}
      <PresentForRoles roles={userPermissions.costs}>
        <li className="text-secondary b-end-cost">Cost: {currencyFormatter.format(bEndPrice ?? 0)}</li>
      </PresentForRoles>
    </ul>
  </PriceCard>
);

export const StyledP2NNI = styled(P2NNI)`
  .nni-text {
    color: ${(props) => props.theme.colours.grey70};
    display: block;
    font-size: 0.75em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
