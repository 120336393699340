import { Supplier } from '../types/supplier';

export const getSupplier = (supplier: Supplier | null, isPoA: boolean | null): Supplier => {
  // TODO: Fix type system so we actually support `null`
  if (supplier) return supplier;
  if (isPoA) return Supplier.NNAT;
  return Supplier.NONE;
};

export function presentSupplierName(supplier: Supplier | string): string {
  switch (supplier) {
    case Supplier.OPENREACH:
      return 'Openreach';
    case Supplier.VIRGIN:
      return 'Virgin';
    case Supplier.BT_WHOLESALE:
      return 'BT Wholesale';
    case Supplier.CITYFIBRE:
      return 'CityFibre';
    case Supplier.VODAFONE:
      return 'Vodafone National';
    case Supplier.NONE:
    case Supplier.ONAT:
    case Supplier.NNAT:
      return 'Neos Networks';
    case Supplier.SKY:
      return 'Sky via Openreach';
    case Supplier.SKY_ITS:
      return 'Sky via ITS';
    case Supplier.TALKTALK:
      return 'PXC';
    default:
      return supplier;
  }
}

export function presentSupplierNameForPriceTile(supplier: Supplier | string | null | undefined): string {
  if (!supplier) {
    return ''; // or any default value you want to return
  }

  const supplierName = presentSupplierName(supplier);

  if(supplierName === "Openreach") return "via Openreach";
  if(supplierName === "CityFibre") return "via CityFibre";
  if(supplierName === "Sky via ITS") return "via ITS";
  if(supplierName === "Sky via Openreach") return "via Openreach";

  return supplierName;
}

export const isNeosNetworksSupplier = (supplier: Supplier) => {
  return [Supplier.NONE, Supplier.ONAT, Supplier.NNAT].includes(supplier);
};

export const supplierListIncludesNeosNeworksSupplier = (list: Supplier[]) => {
  return list.includes(Supplier.NONE) || list.includes(Supplier.ONAT) || list.includes(Supplier.NNAT);
};
